import React from "react";
import ReactHtmlParser from "react-html-parser";
import { StaticQuery, graphql } from "gatsby";
import GalleryAlt from "src/components/gallery/gallery-alt";

// import education from "src/images/community/card-education-programs.jpg";
// import grants from "src/images/community/card-grants.jpg";
// import relations from "src/images/community/card-relations-requests.jpg";
// import sponsorships from "src/images/community/card-sponsorships.jpg";
// import tours from "src/images/community/card-harbor-tours.jpg";


class Timeline1980 extends React.Component {
  constructor(props) {
    super(props);
    this.replaceWithComponents = this.replaceWithComponents.bind(this);
  }

  state = {
    // photos: [education, grants, relations, sponsorships, tours],
    excerpts: ["Picture description", "Another picture description", "More description", "4th description", "Last description"],
    index: 0
  }


  replaceWithComponents(node, index) {
    // Replace Envira Gallery code with Gallery component
    if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("envira-gallery-wrap") > -1) && (node.attribs.class.indexOf("envira-album-wrap") < 0)) {
      const galleryInfo = node.children[1].attribs['data-gallery-images'];

      return <GalleryAlt key={index} galleryInfo={galleryInfo} previewMode="thumbs" />;

      // let galleryConfig;

      // for (let i = 0; i < node.children.length; i++) {
      //   if (node.children[i].attribs['data-gallery-config']) {
      //     galleryConfig = node.children[i];
      //     break;
      //   }
      // }

      // return <Gallery key={index} galleryConfig={galleryConfig.attribs['data-gallery-config']} galleryInfo={galleryConfig.attribs['data-gallery-images']} previewMode="thumbs" downloadable={galleryConfig} />;
    }
  }


  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            wordpressPage(tagNames: { eq: "dev_only_id_timeline_1980" }) {
              id
              slug
              title
              content
            }
          }
        `}

        render={data => (
          <div id="1980--section" className="timeline--page__body__info">
            <div className="content content--subpage envira--section--right">
              {ReactHtmlParser(data.wordpressPage.content, { transform: this.replaceWithComponents })}
            </div>
            <div className="text--section--left">
              <h3 className="heading--med text--section--left__heading">1980</h3>
              <h4 className="text--section--left__title">1981</h4>
              <p className="headline__intro intro">China Ocean Shipping Co. (COSCO) inaugurates international shipping, and it makes Long Beach's Pacific Container Terminal its first U.S. port of call.</p>
              <h4 className="text--section--left__title">1982</h4>
              <p className="headline__intro intro">The Port of Long Beach opens Foreign Trade Zone #50, allowing duty-free manufacturing, storage, repair, testing, exhibition, assembly and labeling of products for U.S. consumption or re-export.</p>
              <h4 className="text--section--left__title">1983</h4>
              <p className="headline__intro intro">The Spruce Goose goes on display to the public in a giant geodesic dome next to the Queen Mary in May. After a decade or so, the giant plane was moved to an aviation museum in McMinnville, Oregon. The dome now serves as the terminal for Carnival Cruises and has been used for special events and occasional filming.</p>
              <h4 className="text--section--left__title">1986</h4>
              <p className="headline__intro intro">International Transportation Service is the first container terminal in Long Beach and Southern California to operate a dockside rail facility for double-stack container trains.</p>
              <p className="headline__intro intro">Long Beach Container Terminal opens an 88-acre facility on Pier F. Maersk opens an expanded 54-acre container terminal on Pier J.</p>
              <h4 className="text--section--left__title">1987</h4>
              <p className="headline__intro intro">The Intermodal Container Transfer Facility opens. Located approximately 4 miles from the ports of Long Beach and Los Angeles, the ICTF is operated by the Southern Pacific Railroad.</p>
              <h4 className="text--section--left__title">1988</h4>
              <p className="headline__intro intro">Construction begins on a 147-acre landfill expansion of Pier J.</p>
              <h4 className="text--section--left__title">1989</h4>
              <p className="headline__intro intro">The port-sponsored World Trade Center opens on a 13.5-acre parcel in downtown Long Beach.</p>
            </div>
          </div>
        )}
      />
    );
  }
}


export default Timeline1980;