import React from "react";
import ReactHtmlParser from "react-html-parser";
import { StaticQuery, graphql } from "gatsby";
import GalleryAlt from "src/components/gallery/gallery-alt";

// import education from "src/images/community/card-education-programs.jpg";
// import grants from "src/images/community/card-grants.jpg";
// import relations from "src/images/community/card-relations-requests.jpg";
// import sponsorships from "src/images/community/card-sponsorships.jpg";
// import tours from "src/images/community/card-harbor-tours.jpg";


class Timeline1940 extends React.Component {
  constructor(props) {
    super(props);
    this.replaceWithComponents = this.replaceWithComponents.bind(this);
  }

  state = {
    // photos: [education, grants, relations, sponsorships, tours],
    excerpts: ["Picture description", "Another picture description", "More description", "4th description", "Last description"],
    index: 0
  }


  replaceWithComponents(node, index) {
    // Replace Envira Gallery code with Gallery component
    if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("envira-gallery-wrap") > -1) && (node.attribs.class.indexOf("envira-album-wrap") < 0)) {
      const galleryInfo = node.children[1].attribs['data-gallery-images'];

      return <GalleryAlt key={index} galleryInfo={galleryInfo} previewMode="thumbs" />;

      // let galleryConfig;

      // for (let i = 0; i < node.children.length; i++) {
      //   if (node.children[i].attribs['data-gallery-config']) {
      //     galleryConfig = node.children[i];
      //     break;
      //   }
      // }

      // return <Gallery key={index} galleryConfig={galleryConfig.attribs['data-gallery-config']} galleryInfo={galleryConfig.attribs['data-gallery-images']} previewMode="thumbs" downloadable={galleryConfig} />;
    }
  }


  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            wordpressPage(tagNames: { eq: "dev_only_id_timeline_1940" }) {
              id
              slug
              title
              content
            }
          }
        `}

        render={data => (
          <div id="1940--section" className="timeline--page__body__info">
            <div className="content content--subpage envira--section--right">
              {ReactHtmlParser(data.wordpressPage.content, { transform: this.replaceWithComponents })}
            </div>
            <div className="text--section--left">
              <h3 className="heading--med text--section--left__heading">1940</h3>
              <h4 className="text--section--left__title">1940</h4>
              <p className="headline__intro intro">The U.S. Navy acquires 100 acres of land on Terminal Island from the City for $1 and establishes a new naval station. The complex employed more than 16,000 people at its wartime peak.</p>
              <p className="headline__intro intro">The Harbor Department moves into a two-story Administration Building at 1333 W. El Embarcadero, near Piers B & C. The location today is in the middle of the Long Beach Container Terminal facility at what is now Pier E.</p>
              <h4 className="text--section--left__title">1941</h4>
              <p className="headline__intro intro">Construction begins on the 2.5-mile eastern leg of the San Pedro Bay breakwater. Work is halted in 1943 due to World War II. Construction resumes in 1946. The extension is completed in 1949, and the breakwater spans more than 8 miles across San Pedro Bay.</p>
              <h4 className="text--section--left__title">1943</h4>
              <p className="headline__intro intro">The oil drilling program includes 126 harbor wells producing 17,000 barrels a day, generating $10 million a year in oil revenue.</p>
              <h4 className="text--section--left__title">1944</h4>
              <p className="headline__intro intro">A pontoon bridge is put in place as a temporary connector between Long Beach and Terminal Island. The floating bridge separates and is retracted when water traffic needs to enter or leave. Rising and falling tides make the trip across an adventure for local drivers, who occasionally drive off and end up in the channel. The temporary span is in place for nearly a quarter of a century before being replaced by the Gerald Desmond Bridge in 1968.</p>
              <h4 className="text--section--left__title">1945</h4>
              <p className="headline__intro intro">Subsidence caused by oil extraction becomes a major concern. Dikes are built for flood control at high tide, and engineers and geologists are assigned to study the problem.</p>
              <h4 className="text--section--left__title">1946</h4>
              <p className="headline__intro intro">The first of nine clear-span transit sheds is completed at Pier A (now Pier F). This establishes Long Beach as "America's most modern port."</p>
              <p className="headline__intro intro">A floating crane, designated YD-171 by the Navy but known to residents of Long Beach as “Herman the German,” begins operations at the Naval Shipyard. The crane, seized from Nazi Germany at the end of World War II, is a familiar sight on the Long Beach skyline for decades. Sold to Panama in 1994, the crane is still in service in the Canal Zone.</p>
              <h4 className="text--section--left__title">1947</h4>
              <p className="headline__intro intro">November 2 - After being trucked in pieces from Culver City to Long Beach the previous year, Howard Hughes’ massive wooden Hercules H-4 flying boat, better known as the “Spruce Goose,” makes its one and only flight in the harbor. After the brief outing, the plane returned to its hangar on Pier Echo (now part of Pier T) for more than 30 years before becoming a Long Beach tourist attraction in the 1980s.</p>
              <h4 className="text--section--left__title">1948</h4>
              <p className="headline__intro intro">Pierpoint Landing opens on what is now Pier F and grows to become the world's largest sportfishing operation, attracting 2 million anglers annually. With restaurants, kids’ rides and a sea-lion pool, it remained a Long Beach attraction until its closure in 1972.</p>
              <h4 className="text--section--left__title">1949</h4>
              <p className="headline__intro intro">Jacobsen Pilot Station is the first in the Western Hemisphere to install a shore-based radar system.</p>
              <p className="headline__intro intro">Pier E is completed, adding 36 acres to the outer harbor. Pier B is doubled in size.</p>
            </div>
          </div>
        )}
      />
    );
  }
}


export default Timeline1940;