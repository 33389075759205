import React from "react";
import ReactHtmlParser from "react-html-parser";
import { StaticQuery, graphql } from "gatsby";
import GalleryAlt from "src/components/gallery/gallery-alt";

// import education from "src/images/community/card-education-programs.jpg";
// import grants from "src/images/community/card-grants.jpg";
// import relations from "src/images/community/card-relations-requests.jpg";
// import sponsorships from "src/images/community/card-sponsorships.jpg";
// import tours from "src/images/community/card-harbor-tours.jpg";
// import ArrowButton from "src/images/btn-chevron.png";


class Timeline1890 extends React.Component {
    constructor(props) {
        super(props);
        // this.handlePreviousImageClick = this.handlePreviousImageClick.bind(this);
        // this.handleNextImageClick = this.handleNextImageClick.bind(this);
        this.replaceWithComponents = this.replaceWithComponents.bind(this);
    }

    state = {
        // photos: [education, grants, relations, sponsorships, tours],
        excerpts: ["Picture description", "Another picture description", "More description", "4th description", "Last description"],
        index: 0
    }

    // handlePreviousImageClick() {
    //     this.setState({ index: this.state.index - 1 });
    // }

    // handleNextImageClick() {
    //     this.setState({ index: this.state.index + 1 });
    // }


    replaceWithComponents(node, index) {
        // Replace Envira Gallery code with Gallery component
        if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("envira-gallery-wrap") > -1) && (node.attribs.class.indexOf("envira-album-wrap") < 0)) {
            const galleryInfo = node.children[1].attribs['data-gallery-images'];

            return <GalleryAlt key={index} galleryInfo={galleryInfo} previewMode="thumbs" />;

            // let galleryConfig;

            // for (let i = 0; i < node.children.length; i++) {
            //     if (node.children[i].attribs['data-gallery-config']) {
            //         galleryConfig = node.children[i];
            //         break;
            //     }
            // }

            // return <Gallery key={index} galleryConfig={galleryConfig.attribs['data-gallery-config']} galleryInfo={galleryConfig.attribs['data-gallery-images']} previewMode="thumbs" downloadable={galleryConfig} />;
        }
    }


    render() {
        return (
            <StaticQuery
                query={graphql`
                    query {
                        wordpressPage(tagNames: { eq: "dev_only_id_timeline_1890" }) {
                            id
                            slug
                            title
                            content
                        }
                    }
                `}

                render={data => (
                    <div id="1890--section" className="timeline--page__body__info">
                        <div className="content content--subpage envira--section--left">
                            {ReactHtmlParser(data.wordpressPage.content, { transform: this.replaceWithComponents })}
                        </div>
                        {/*<div className="photo--section--left">
                            <img src={this.state.photos[this.state.index]} alt="" className="photo--section--left__image" />
                            <p className="photo--section--left__text headline__intro intro">{this.state.excerpts[this.state.index]}</p>
                            <div className="photo--section--left__image--switch">
                                <button type="button" disabled={this.state.index === 0} onClick={this.handlePreviousImageClick} className="global-search-close form-search-subpage__btn button--hover--cursor previous--image"><img src={ArrowButton} alt="" /></button>
                                <h4 className="image--number--display">{this.state.index + 1} of 5</h4>
                                <button type="button" disabled={this.state.index === 4} onClick={this.handleNextImageClick} className="global-search-close form-search-subpage__btn button--hover--cursor next--image"><img src={ArrowButton} alt="" /></button>
                            </div>
                        </div>*/}
                        <div className="text--section--right">
                            <h3 className="heading--med text--section--right__heading">1890</h3>
                            <h4 className="text--section--right__title">1897</h4>
                            <p className="headline__intro intro">After a political battle that lasted several years, the federal government selects San Pedro Bay over Santa Monica Bay for harbor development, paving the way for the ports of Long Beach and Los Angeles.</p>
                            <h4 className="text--section--right__title">1899</h4>
                            <p className="headline__intro intro">Construction of the San Pedro Bay breakwater begins.</p>
                        </div>
                    </div>
                )}
            />
        );
    }
}


export default Timeline1890;