import React from "react";
import ReactHtmlParser from "react-html-parser";
import { StaticQuery, graphql } from "gatsby";
import GalleryAlt from "src/components/gallery/gallery-alt";

// import education from "src/images/community/card-education-programs.jpg";
// import grants from "src/images/community/card-grants.jpg";
// import relations from "src/images/community/card-relations-requests.jpg";
// import sponsorships from "src/images/community/card-sponsorships.jpg";
// import tours from "src/images/community/card-harbor-tours.jpg";


class Timeline1950 extends React.Component {
  constructor(props) {
    super(props);
    this.replaceWithComponents = this.replaceWithComponents.bind(this);
  }

  state = {
    // photos: [education, grants, relations, sponsorships, tours],
    excerpts: ["Picture description", "Another picture description", "More description", "4th description", "Last description"],
    index: 0
  }


  replaceWithComponents(node, index) {
    // Replace Envira Gallery code with Gallery component
    if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("envira-gallery-wrap") > -1) && (node.attribs.class.indexOf("envira-album-wrap") < 0)) {
      const galleryInfo = node.children[1].attribs['data-gallery-images'];

      return <GalleryAlt key={index} galleryInfo={galleryInfo} previewMode="thumbs" />;

      // let galleryConfig;

      // for (let i = 0; i < node.children.length; i++) {
      //   if (node.children[i].attribs['data-gallery-config']) {
      //     galleryConfig = node.children[i];
      //     break;
      //   }
      // }

      // return <Gallery key={index} galleryConfig={galleryConfig.attribs['data-gallery-config']} galleryInfo={galleryConfig.attribs['data-gallery-images']} previewMode="thumbs" downloadable={galleryConfig} />;
    }
  }


  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            wordpressPage(tagNames: { eq: "dev_only_id_timeline_1950" }) {
              id
              slug
              title
              content
            }
          }
        `}

        render={data => (
          <div id="1950--section" className="timeline--page__body__info">
            <div className="content content--subpage envira--section--left">
              {ReactHtmlParser(data.wordpressPage.content, { transform: this.replaceWithComponents })}
            </div>
            <div className="text--section--right">
              <h3 className="heading--med text--section--right__heading">1950</h3>
              <h4 className="text--section--right__title">1951</h4>
              <p className="headline__intro intro">An amendment to the 1911 Tidelands Grant is passed, authorizing use of half the tidelands oil revenues on non-harbor projects.</p>
              <h4 className="text--section--right__title">1953</h4>
              <p className="headline__intro intro">A long-simmering dispute over who owned the tidelands and its oil revenues – the federal government or the state and local authorities – is settled with the passage of a bill restoring control of undersea lands to coastal states. For his role in passing the bill and supporting state ownership, President Dwight Eisenhower is honored with the Port’s inaugural Honorary Port Pilot Award.</p>
              <h4 className="text--section--right__title">1955</h4>
              <p className="headline__intro intro">The U.S. Supreme Court determines that half the oil and all gas revenues freed from the terms of the 1911 state grant should go to the State of California and not to Long Beach.</p>
              <h4 className="text--section--right__title">1956</h4>
              <p className="headline__intro intro">State and City officials reach agreement over the tidelands revenues. Long Beach turns over $120 million to the state and promises to pay all gas revenues and 50% of all future tidelands oil revenues to California.</p>
              <h4 className="text--section--right__title">1957</h4>
              <p className="headline__intro intro">Subsidence worsens as a 16-square-mile area in the north harbor sinks between 2 and 24 feet. Millions of dollars are spent on dikes and pumps to protect Port operations. Due to subsidence issues that eventually caused a major fire, Ford announces that it is closing its Long Beach plant. The Navy also warns Long Beach that subsidence is threatening its continued presence in the city.</p>
            </div>
          </div>
        )}
      />
    );
  }
}


export default Timeline1950;