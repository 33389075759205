import React from "react";
import ReactHtmlParser from "react-html-parser";
import { StaticQuery, graphql } from "gatsby";
import GalleryAlt from "src/components/gallery/gallery-alt";

// import education from "src/images/community/card-education-programs.jpg";
// import grants from "src/images/community/card-grants.jpg";
// import relations from "src/images/community/card-relations-requests.jpg";
// import sponsorships from "src/images/community/card-sponsorships.jpg";
// import tours from "src/images/community/card-harbor-tours.jpg";


class Timeline1990 extends React.Component {
  constructor(props) {
    super(props);
    this.replaceWithComponents = this.replaceWithComponents.bind(this);
  }

  state = {
    // photos: [education, grants, relations, sponsorships, tours],
    excerpts: ["Picture description", "Another picture description", "More description", "4th description", "Last description"],
    index: 0
  }


  replaceWithComponents(node, index) {
    // Replace Envira Gallery code with Gallery component
    if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("envira-gallery-wrap") > -1) && (node.attribs.class.indexOf("envira-album-wrap") < 0)) {
      const galleryInfo = node.children[1].attribs['data-gallery-images'];

      return <GalleryAlt key={index} galleryInfo={galleryInfo} previewMode="thumbs" />;

      // let galleryConfig;

      // for (let i = 0; i < node.children.length; i++) {
      //   if (node.children[i].attribs['data-gallery-config']) {
      //     galleryConfig = node.children[i];
      //     break;
      //   }
      // }

      // return <Gallery key={index} galleryConfig={galleryConfig.attribs['data-gallery-config']} galleryInfo={galleryConfig.attribs['data-gallery-images']} previewMode="thumbs" downloadable={galleryConfig} />;
    }
  }


  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            wordpressPage(tagNames: { eq: "dev_only_id_timeline_1990" }) {
              id
              slug
              title
              content
            }
          }
        `}

        render={data => (
          <div id="1990--section" className="timeline--page__body__info">
            <div className="content content--subpage envira--section--left">
              {ReactHtmlParser(data.wordpressPage.content, { transform: this.replaceWithComponents })}
            </div>
            <div className="text--section--right">
              <h3 className="heading--med text--section--right__heading">1990</h3>
              <h4 className="text--section--right__title">1990</h4>
              <p className="headline__intro intro">Toyota nearly doubles its acreage to 144 acres in the north harbor.</p>
              <h4 className="text--section--right__title">1991</h4>
              <p className="headline__intro intro">Hanjin Shipping Co. of South Korea opens a 57-acre container terminal on the site of the former Procter & Gamble plant on Pier C.</p>
              <h4 className="text--section--right__title">1992</h4>
              <p className="headline__intro intro">Hanjin introduces post-Panamax vessels into its trans-Pacific fleet – the first vessels too large for the Panama Canal to call Long Beach. The vessels carry 4,000 TEUs (twenty-foot cargo container equivalent units).</p>
              <p className="headline__intro intro">Hyundai of South Korea introduces 4,400-TEU vessels into its Pacific Southwest Service between Asia and Long Beach, thereby expanding the worldwide post-Panamax fleet.</p>
              <h4 className="text--section--right__title">1993</h4>
              <p className="headline__intro intro">Maersk Line opens a 107-acre container terminal on the 147-acre Pier J expansion. The new terminal features a wharf with a flexible, multiple-direction piling concept that disperses stress and reduces damage in the event of an earthquake.</p>
              <p className="headline__intro intro">The remaining 40 acres of the 147-acre Pier J expansion are incorporated into Pacific Container Terminal.</p>
              <p className="headline__intro intro">Many of the Port’s piers are renamed, with Pier A becoming Pier F and Piers B, C and D becoming Piers D and E.</p>
              <h4 className="text--section--right__title">1994</h4>
              <p className="headline__intro intro">Metropolitan Stevedore Co. opens a $20 million, 175,000-ton coal storage shed to permit ships to be filled entirely from dockside storage.</p>
              <p className="headline__intro intro">The operating agreement for the Alameda Corridor, a 20-mile train and truck expressway from the ports to the transcontinental railyards in Los Angeles, is signed by the ports of Long Beach and Los Angeles and the Santa Fe, Southern Pacific and Union Pacific railroads.</p>
              <p className="headline__intro intro">Long Beach finalizes the purchase in the North Harbor of 725 acres of land and water area from the Union Pacific Resources Co.</p>
              <h4 className="text--section--right__title">1995</h4>
              <p className="headline__intro intro">Long Beach becomes the No. 1 container port in the United States after moving the equivalent of 2.6 million TEUs.</p>
              <p className="headline__intro intro">The port breaks ground on a new container terminal for Hanjin.</p>
              <p className="headline__intro intro">Sea-Land launches the first of its post-Panamax vessels that carry 4,000 containers per voyage. Former first lady Barbara Bush visits the port and christens the Orient Overseas Container Lines' America. The Hong Kong line's megaships carry 4,960 TEUs.</p>
              <h4 className="text--section--right__title">1996</h4>
              <p className="headline__intro intro">Hyundai plies the Pacific with vessels that carry 5,551 TEUs.</p>
              <p className="headline__intro intro">Hanjin deploys 5,000-TEU vessels into its Pacific Southwest Express fleet.</p>
              <h4 className="text--section--right__title">1997</h4>
              <p className="headline__intro intro">COSCO joins other trans-Pacific carriers and launches vessels that carry 5,250 twenty-foot containers.</p>
              <p className="headline__intro intro">The port opens a 170-acre, $277 million container terminal for Hanjin. The terminal is Long Beach's largest, and it is the largest terminal operated anywhere in the world by Hanjin. The terminal features six gantry cranes, a 3,600-foot wharf, and the port's fifth dock-side rail facility.</p>
              <p className="headline__intro intro">Construction begins on the Alameda Corridor — a 20-mile rail expressway that will connect the ports of Long Beach and Los Angeles with transcontinental rail yards near downtown Los Angeles.</p>
              <p className="headline__intro intro">Port officials join federal, state and local entities to restore the Bolsa Chica wetlands near Huntington Beach in exchange for further port development in San Pedro Bay. Long Beach's $39 million contribution to the project allows for at least 267 acres of future landfill at the port.</p>
              <h4 className="text--section--right__title">1998</h4>
              <p className="headline__intro intro">With a total of 4,097,689 TEUs, Long Beach becomes the first North American seaport to handle the equivalent of 4 million 20-foot-long cargo containers in a single year.</p>
              <p className="headline__intro intro">The Navy agrees to transfer its 500-acre Terminal Island complex to the port for redevelopment. Congress ordered the complex closed as part of a series of base closures at the end of the Cold War. The land was formerly home to the Long Beach Naval Station and the Long Beach Naval Shipyard.</p>
              <p className="headline__intro intro">The port breaks ground for its first "mega-terminal," a 375-acre cargo facility at the former Naval complex on Terminal Island.</p>
              <h4 className="text--section--right__title">1999</h4>
              <p className="headline__intro intro">The Hanjin Terminal becomes Long Beach's first container terminal to handle more than 1 million TEUs in a single year.</p>
              <p className="headline__intro intro">Sea Launch, an international consortium led by Boeing and based at the port, sails to the equator and rockets its first commercial satellite into orbit. The launch makes Long Beach the world's first seaport to export into outer space.</p>
              <p className="headline__intro intro">Carnival Cruises begins operations at the Port of Long Beach.</p>
            </div>
          </div>
        )}
      />
    );
  }
}


export default Timeline1990;