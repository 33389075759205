import React from "react";

/**
 * Gallery: thumbnails view
 * react-images does not have this, so it's entirely custom.
 */
class GalleryThumbnails extends React.Component {

  // Switch gallery to item for thumb selected
  handleShowItemInGallery = (selectedIndex) => {
    this.props.handleThumbnailSelected(selectedIndex);
  };

  render() {
    const { thumbs, showThumbs } = this.props;
    // console.log("thumbnails:", this.props);

    // For testing purposes only. Will show more thumbs in grid.
    // const testThumbsCopy = thumbs;
    // const testThumbsBunch = thumbs.concat(testThumbsCopy);
    // const testThumbs = testThumbsBunch.concat(testThumbsCopy);
    // console.log(testThumbs);

    // console.log(thumbs);

    return (
      <section className={`gallery__thumbs-wrapper ${showThumbs && 'gallery__thumbs-wrapper--isOpen'}`}>
        <ul className="gallery__thumbs">
          {
            thumbs.map((thumb, i) => {
              return (
                <li key={i}>
                  <img 
                    src={thumb.source.thumbnail} 
                    alt={`Thumbnail: ${thumb.caption}`} 
                    className="gallery__thumb" 
                    onClick={() => this.handleShowItemInGallery(i)} 
                    title="View item in carousel"
                  />
                </li>
              )
            })
          }
        </ul>
      </section>
    );
  }
}

export default GalleryThumbnails;
