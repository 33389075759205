import React from "react";
import ReactHtmlParser from "react-html-parser";
import { StaticQuery, graphql } from "gatsby";
import GalleryAlt from "src/components/gallery/gallery-alt";

// import education from "src/images/community/card-education-programs.jpg";
// import grants from "src/images/community/card-grants.jpg";
// import relations from "src/images/community/card-relations-requests.jpg";
// import sponsorships from "src/images/community/card-sponsorships.jpg";
// import tours from "src/images/community/card-harbor-tours.jpg";


class Timeline2000 extends React.Component {
  constructor(props) {
    super(props);
    this.replaceWithComponents = this.replaceWithComponents.bind(this);
  }

  state = {
    // photos: [education, grants, relations, sponsorships, tours],
    excerpts: ["Picture description", "Another picture description", "More description", "4th description", "Last description"],
    index: 0
  }


  replaceWithComponents(node, index) {
    // Replace Envira Gallery code with Gallery component
    if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("envira-gallery-wrap") > -1) && (node.attribs.class.indexOf("envira-album-wrap") < 0)) {
      const galleryInfo = node.children[1].attribs['data-gallery-images'];

      return <GalleryAlt key={index} galleryInfo={galleryInfo} previewMode="thumbs" />;

      // let galleryConfig;

      // for (let i = 0; i < node.children.length; i++) {
      //   if (node.children[i].attribs['data-gallery-config']) {
      //     galleryConfig = node.children[i];
      //     break;
      //   }
      // }

      // return <Gallery key={index} galleryConfig={galleryConfig.attribs['data-gallery-config']} galleryInfo={galleryConfig.attribs['data-gallery-images']} previewMode="thumbs" downloadable={galleryConfig} />;
    }
  }


  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            wordpressPage(tagNames: { eq: "dev_only_id_timeline_2000" }) {
              id
              slug
              title
              content
            }
          }
        `}

        render={data => (
          <div id="2000--section" className="timeline--page__body__info">
            <div className="content content--subpage envira--section--right">
              {ReactHtmlParser(data.wordpressPage.content, { transform: this.replaceWithComponents })}
            </div>
            <div className="text--section--left">
              <h3 className="heading--med text--section--left__heading">2000</h3>
              <h4 className="text--section--left__title">2000</h4>
              <p className="headline__intro intro">Hanjin Shipping Co. of South Korea signs a 25-year, $1 billion lease for the new Pier T terminal on Terminal Island. The 375-acre facility will feature 5,000 lineal feet of wharf, at least 50-foot water depths, a 30-lane truck gate complex, electrical plugs for 1,200 refrigerated containers and a dockside rail yard.</p>
              <p className="headline__intro intro">The port moves petroleum coke piles into sheds and implements dust control measures, greatly improving air quality in the area and surrounding communities.</p>
              <h4 className="text--section--left__title">2001</h4>
              <p className="headline__intro intro">The U.S. Navy transfers the final piece of land on Terminal Island to the port, ending a nearly century-long presence in Long Beach.</p>
              <h4 className="text--section--left__title">2002</h4>
              <p className="headline__intro intro">The port's largest terminal, dubbed the first "mega-terminal," opens its doors at Pier T on Terminal Island.</p>
              <p className="headline__intro intro">April 15 — The Alameda Corridor opens, allowing freight trains to travel from the ports to transcontinental rail yards near downtown Los Angeles quickly, without disrupting road traffic.</p>
              <h4 className="text--section--left__title">2003</h4>
              <p className="headline__intro intro">The era of megaships begins at the Port with the maiden call of the 8,000-TEU OOCL Long Beach at Pier F. A year later, the 8,500-TEU CSCL Asia would call at Pier T. The Chinese operated vessels were some the biggest container ships in the world at the time, capable of carrying a third more containers than the previous models.</p>
              <h4 className="text--section--left__title">2004</h4>
              <p className="headline__intro intro">Terminal operators unveil PierPASS, a first of its kind program designed to ease traffic congestion and reduce air pollution by shifting a portion of truck trips to nights and weekends.</p>
              <h4 className="text--section--left__title">2005</h4>
              <p className="headline__intro intro">The Port of Long Beach adopts the Green Port Policy, making environmental protection a top priority for one of the largest seaports in the world and making Long Beach a global leader in the field.</p>
              <h4 className="text--section--left__title">2006</h4>
              <p className="headline__intro intro">The Port of Long Beach and the Port of Los Angeles adopt the San Pedro Clean Air Action Plan, an unprecedented and comprehensive strategy to cut air pollution from port related operations.</p>
              <h4 className="text--section--left__title">2007</h4>
              <p className="headline__intro intro">In a nod to transparency, the port starts airing its board meetings on the web and cable television.</p>
              <h4 className="text--section--left__title">2008</h4>
              <p className="headline__intro intro">The Clean Trucks Program begins with progressive bans on the oldest, most polluting drayage trucks. Goal is to cut truck related pollution by 80 percent in four years. It is reached in two. The first container ship "plugs in" at Pier G using shore power (right), which allows ships to draw cleaner electricity from land instead of their diesel engines.</p>
              <h4 className="text--section--left__title">2009</h4>
              <p className="headline__intro intro">The port opens its Joint Command and Control Center, a state-of-the-art security facility designed to usher in a new era of cooperation among various agencies.</p>
              <p className="headline__intro intro">BP dedicates the first oil tanker terminal in the world with shore power at Pier T.</p>
              <p className="headline__intro intro">The Middle Harbor project, which will combine two terminals and double capacity while cutting pollution in half, gets unanimous City Council support.</p>
            </div>
          </div>
        )}
      />
    );
  }
}


export default Timeline2000;