import React from "react";
import ReactHtmlParser from "react-html-parser";
import { StaticQuery, graphql } from "gatsby";
import GalleryAlt from "src/components/gallery/gallery-alt";

// import education from "src/images/community/card-education-programs.jpg";
// import grants from "src/images/community/card-grants.jpg";
// import relations from "src/images/community/card-relations-requests.jpg";
// import sponsorships from "src/images/community/card-sponsorships.jpg";
// import tours from "src/images/community/card-harbor-tours.jpg";


class Timeline2010 extends React.Component {
  constructor(props) {
    super(props);
    this.replaceWithComponents = this.replaceWithComponents.bind(this);
  }

  state = {
    // photos: [education, grants, relations, sponsorships, tours],
    excerpts: ["Picture description", "Another picture description", "More description", "4th description", "Last description"],
    index: 0
  }


  replaceWithComponents(node, index) {
    // Replace Envira Gallery code with Gallery component
    if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("envira-gallery-wrap") > -1) && (node.attribs.class.indexOf("envira-album-wrap") < 0)) {
      const galleryInfo = node.children[1].attribs['data-gallery-images'];

      return <GalleryAlt key={index} galleryInfo={galleryInfo} previewMode="thumbs" />;

      // let galleryConfig;

      // for (let i = 0; i < node.children.length; i++) {
      //   if (node.children[i].attribs['data-gallery-config']) {
      //     galleryConfig = node.children[i];
      //     break;
      //   }
      // }

      // return <Gallery key={index} galleryConfig={galleryConfig.attribs['data-gallery-config']} galleryInfo={galleryConfig.attribs['data-gallery-images']} previewMode="thumbs" downloadable={galleryConfig} />;
    }
  }


  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            wordpressPage(tagNames: { eq: "dev_only_id_timeline_2010" }) {
              id
              slug
              title
              content
            }
          }
        `}

        render={data => (
          <div id="2010--section" className="timeline--page__body__info">
            <div className="content content--subpage envira--section--left">
              {ReactHtmlParser(data.wordpressPage.content, { transform: this.replaceWithComponents })}
            </div>
            <div className="text--section--right">
              <h3 className="heading--med text--section--right__heading">2010</h3>
              <h4 className="text--section--right__title">2010</h4>
              <p className="headline__intro intro">A project is launched to replace the aging Gerald Desmond Bridge, which handles 15 percent of U.S. cargo.</p>
              <p className="headline__intro intro">Virtually all older drayage trucks are banned from Port terminals under the Clean Trucks Program, which reaches its clean air goals two years ahead of schedule.</p>
              <p className="headline__intro intro">Containerized cargo at the port increases by 1.2 million units in 2010, the largest single increase of any seaport in the United States. The jump represents a nearly 25 percent gain over 2009, the largest one-year increase in Port history.</p>
              <p className="headline__intro intro">Thousands attend the Port of Long Beach's annual Green Port Fest in October, an opportunity for the public to learn more about the Port's operations and environmental programs in a fun, street festival setting. The 2010 event features Long Beach Arena muralist Wyland.</p>
              <h4 className="text--section--right__title">2011</h4>
              <p className="headline__intro intro">The Port of Long Beach celebrates its Centennial, culminating in June with a huge birthday party held on Pier E (now part of Long Beach Container Terminal). Thousands of people attend the event, which features carnival rides, Port history exhibits and screenings of “Faces of the Port,” a documentary about the Port’s first century.</p>
              <h4 className="text--section--right__title">2012</h4>
              <p className="headline__intro intro">The Port’s Clean Trucks Program reaches its final milestone on Jan. 1, with the last older container trucks banned from Port terminals, meaning all 11,000 drayage trucks servicing the Port are now 2007 or newer vehicles. Over five years, the program has cut truck-related air pollution by 90 percent.</p>
              <p className="headline__intro intro">In March, the Port welcomes the MSC Fabiola, a 1,200-foot-long, 12,500-TEU ship that at the time is the largest container vessel to call at a North American port. As more terminals at the Port become “Big Ship Ready,” even larger vessels will dock in Long Beach in the coming months.</p>
              <p className="headline__intro intro">The Port and Orient Overseas Container Line sign a historic $4.6 billion, 40-year lease for the new Middle Harbor container terminal in April. The mega-terminal, which will handle twice the cargo of previous terminals in the space, but with only half the pollution, is scheduled to become operational in stages, with full completion scheduled for 2019. A ceremony including the driving of a ceremonial “golden pile” is held in May to commemorate the signing.</p>
              <p className="headline__intro intro">The Board of Harbor Commissioners approves the design-build contract for the construction of the replacement for the Gerald Desmond Bridge. The contract is awarded to a joint venture team headed by Shimmick Construction Co. Inc., FCC Construction S.A. and Impregilo S.p.A.</p>
              <p className="headline__intro intro">In August, the Colorado Lagoon in Long Beach reopens after a major restoration project. The Port contributed $2 million to the project and saved the project another $2 million by accepting dredged contaminated sediments.</p>
              <p className="headline__intro intro">CMA CGM, the world’s third-largest container shipping line, purchases a stake in the lease and operations of the Port’s Pier J terminal, the company’s first investment on the West Coast. CMA CGM joins SSA Marine and COSCO in operating Pacific Container Terminal on Pier J.</p>
              <h4 className="text--section--right__title">2013</h4>
              <p className="headline__intro intro">Construction officially begins on the Gerald Desmond Bridge Replacement Project. Over the next few months, work on the project proceeds, including the demolition of the Pier T offramp from Ocean Boulevard. The project faces challenges, including the presence of dozens of active and abandoned oil wells beneath the project, some of which are hard to locate using plans or maps following decades of subsidence. Many of the wells, abandoned years ago, must be remediated using today’s environmental standards.</p>
              <p className="headline__intro intro">In a sign that the Port is recovering from the global recession, cargo volumes for 2013 rise 11.3 percent to 6,730,573 TEUs, the Port’s third-busiest year behind only 2006 and 2007.</p>
              <h4 className="text--section--right__title">2014</h4>
              <p className="headline__intro intro">In February, most of the Harbor Department’s staff moves from the Administration Building at 925 Harbor Plaza to Interim Administrative Offices at 4801 Airport Plaza Drive, just south of the Long Beach Airport. The interim offices are the department’s fourth headquarters. The previous Administration Building, completed in 1960, was found to be seismically inadequate. The interim offices will be the Port’s headquarters for five years while a new Civic Center is constructed.</p>
              <h4 className="text--section--right__title">2015</h4>
              <p className="headline__intro intro">Several months of congestion at the San Pedro Bay ports end with an agreement on a gray chassis pool, where truck chassis no longer have to be returned to the same facility they are picked up from, and a labor agreement between the Pacific Maritime Association and the International Longshore and Warehouse Union.</p>
              <p className="headline__intro intro">The Green Port Gateway, a $93 million project to eliminate a rail bottleneck under Ocean Boulevard and lay 6 miles of new track, is completed.</p>
              <p className="headline__intro intro">"Port Town", an in-depth history of the Port commissioned by the Port and written by veteran maritime journalists George and Carmela Cunningham, is released. An exhibit at the Historical Society of Long Beach is held to mark the release of the book, which is still available in hardcover, print-on-demand and electronic editions.</p>
              <h4 className="text--section--right__title">2016</h4>
              <p className="headline__intro intro">The Port begins sponsorship of the Academy of Global Logistics at Cabrillo High School, a four-year global trade and logistics pathway that will bring real-world experiences to the classroom. The small learning community was created in partnership with the Long Beach Unified School District.</p>
              <p className="headline__intro intro">Protector, a new state-of-the-art fireboat, goes into service at the Port in June. Protector is equipped with 10 water cannons capable of extinguishing fires in the harbor or on nearby land with more than 41,000 gallons per minute — four times the output of existing fireboats. Protector can shoot water the length of two football fields, and higher than a 20-story building. A second fireboat, the Vigilance, is delivered in 2017.</p>
              <p className="headline__intro intro">The Board of Harbor Commissioners creates the Community Grants Program, with funding for environmental projects aimed at reducing Port impacts on the community. With $46.4 million set aside for spending over 12 to 15 years, it is the largest voluntary program of its kind in the nation.</p>
              <h4 className="text--section--right__title">2017</h4>
              <p className="headline__intro intro">The San Pedro Bay ports update the Clean Air Action Plan, established in 2006 and updated in 2010, with new pollution reduction strategies and an eventual goal of zero-emissions operations.</p>
              <p className="headline__intro intro">The Port celebrates a record cargo year, moving 7.54 million TEUs, an increase of more than 11 percent over 2016, despite a rapidly changing world of shipping alliances and the bankruptcy of a major tenant the previous year.</p>
              <h4 className="text--section--right__title">2018</h4>
              <p className="headline__intro intro">The Board of Harbor Commissioners approves a plan for Toyota Logistics Services to redevelop its facility on Pier B and build a renewable energy fuel-cell power plant and hydrogen fueling station.</p>
              <p className="headline__intro intro">In a parallel to the Academy of Global Logistics at Cabrillo High School launched in 2016, the Port partners with Long Beach City College to create the Maritime Center of Excellence, which offers a variety of programs for occupations in the global logistics and supply chain industries that require more education than a high school diploma, but less than a four-year college degree.</p>
              <p className="headline__intro intro">An $870 million budget is approved to build the Pier B On-Dock Rail Support Facility in the Port’s Inner Harbor. Designed to shift more shipping containers from trucks to rail, the facility will result in a more efficient and sustainable transfer of cargo, helping the Port of Long Beach to stay competitive and meet environmental targets. The first arrival, departure and storage tracks are expected to be completed in 2024, with additional tracks coming online in 2030, followed by project completion in 2032.</p>
              <p className="headline__intro intro">The Port has another record year, passing the 8 million TEU mark for the first time. The total cargo volume is a 7.2 percent rise over 2017, also a record year.</p>
              <h4 className="text--section--right__title">2019</h4>
              <p className="headline__intro intro">An economic impact study commissioned by the Port shows that trade through the Port of Long Beach supports 1 in 5 Long Beach jobs – about 51,000; 575,000 jobs in Southern California, 705,000 jobs statewide and 2.6 million jobs across the U.S. Port-related jobs across the city had increased by 70 percent since the previous economic impact study, completed in 2004, mainly due to increased cargo volume.</p>
              <p className="headline__intro intro">The Port agrees to fund a $26.3 million project to improve water and habitat quality at the Colorado Lagoon in exchange for environmental mitigation credits that would allow for future Port development. The Port had provided $2.3 million to fund an earlier phase of lagoon restoration.</p>
              <p className="headline__intro intro">The Port returns to the waterfront, moving in July into its new Administration Building at the new Long Beach Civic Center, adjacent to City Hall. The building, 415 W. Ocean Blvd., at the corner of Magnolia Avenue and Ocean Boulevard, is constructed to LEED Gold environmental standards.</p>
            </div>
          </div>
        )}
      />
    );
  }
}


export default Timeline2010;