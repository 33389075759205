import React from "react";

/**
 * Gallery: custom view for react-images. Represents a single image or video shown in gallery.
 * This is used instead of the default react-images <View /> component, which only supports images.
 * See http://jossmac.github.io/react-images/#/components for more info on custom components.
 */
class GalleryCustomView extends React.Component {
  render() {
    // react-images automatically passes in the info for the specific media item being rendered as an object named data on props
    const { data } = this.props;
    // console.log(this.props);
    let containerElement = document.getElementsByClassName("react-images__track")[0];

    let isSafari = navigator.userAgent.toLowerCase();
    if (isSafari.indexOf('safari') !== -1) {
      if (isSafari.indexOf('chrome') < 0) {
        let arrayLength = this.props.carouselProps.views.length;
        let properWidth = 100 * arrayLength;
        if (containerElement) {
          containerElement.style.width = `${properWidth}%`;
        }
      }
    }

    let safariAlternative = (isSafari.indexOf('safari') !== -1 && isSafari.indexOf('chrome') < 0) ?
      <React.Fragment>
        {
          containerElement &&
            <React.Fragment>
              {
                !this.props.currentView.video
                  ?
                  <div className="react-images__view react-images__view--isModal">
                    <img src={this.props.currentView.source.regular} alt={this.props.currentView.caption} className="react-images__view-image" />
                  </div>
                  :
                  <div className="react-images__view--isVideo react-images__view--isModal">
                    <iframe src={this.props.currentView.video.embed_url} width="640" height="360" title={this.props.currentView.caption} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen className="react-images__view-video"></iframe>
                  </div>
              }
            </React.Fragment>
        }
      </React.Fragment>
      :
      null;


    return (
      <React.Fragment>
        {
          (isSafari.indexOf('safari') !== -1 && isSafari.indexOf('chrome') < 0) ?
            <React.Fragment>
              {safariAlternative}
            </React.Fragment>
            :
            <React.Fragment>
              {
                !data.video
                  ?
                  <div className="react-images__view react-images__view--isModal">
                    <img src={data.source.regular} alt={data.caption} className="react-images__view-image" />
                  </div>
                  :
                  <div className="react-images__view--isVideo react-images__view--isModal">
                    <iframe src={data.video.embed_url} width="640" height="360" title={data.caption} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen className="react-images__view-video"></iframe>
                  </div>
              }
            </React.Fragment>
        }
      </React.Fragment>
    );
  }
}

export default GalleryCustomView;







// import React from "react";

// /**
//  * Gallery: custom view for react-images. Represents a single image or video shown in gallery.
//  * This is used instead of the default react-images <View /> component, which only supports images.
//  * See http://jossmac.github.io/react-images/#/components for more info on custom components.
//  */
// class GalleryCustomView extends React.Component {
//   render() {
//     // react-images automatically passes in the info for the specific media item being rendered as an object named data on props
//     const { data } = this.props;
//     // console.log("We made it");
//     // console.log(this.props);
//     // console.log(this.props.carouselProps.views.length);

//     var isSafari = navigator.userAgent.toLowerCase();
//     if (isSafari.indexOf('safari') != -1) {
//       if (isSafari.indexOf('chrome') < 0) {
//         let arrayLength = this.props.carouselProps.views.length;
//         let properWidth = 100 * arrayLength;
//         let containerElement = document.getElementsByClassName("react-images__track")[0];
//         // containerElement.setAttribute("style", `width: ${properWidth}%;`);
//         console.log(containerElement);
//         // console.log(containerElement.getAttribute("style")); // Safari
//       }
//     }
//     // let isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
//     // if (isSafari) {
//     //   console.log(document.getElementsByClassName("react-images__track"));
//     // }

//     return (
//       <React.Fragment>
//         {
//           !data.video
//             ?
//             <div className="react-images__view react-images__view--isModal">
//               <img src={data.source.regular} alt={data.caption} className="react-images__view-image" />
//             </div>
//             :
//             <div className="react-images__view--isVideo react-images__view--isModal">
//               <iframe src={data.video.embed_url} width="640" height="360" title={data.caption} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen className="react-images__view-video"></iframe>
//             </div>
//         }
//       </React.Fragment>
//     );
//   }
// }

// export default GalleryCustomView;
