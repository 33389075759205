import React from "react";
import { Link } from "gatsby";
import VisabilitySensor from 'react-visibility-sensor';
import "src/styles/polb.scss";

import Icons from "src/components/icons";
import ArrowButton from "src/images/btn-chevron.png";
import Timeline1890 from "src/components/timeline-1890";
import Timeline1900 from "src/components/timeline-1900";
import Timeline1910 from "src/components/timeline-1910";
import Timeline1920 from "src/components/timeline-1920";
import Timeline1930 from "src/components/timeline-1930";
import Timeline1940 from "src/components/timeline-1940";
import Timeline1950 from "src/components/timeline-1950";
import Timeline1960 from "src/components/timeline-1960";
import Timeline1970 from "src/components/timeline-1970";
import Timeline1980 from "src/components/timeline-1980";
import Timeline1990 from "src/components/timeline-1990";
import Timeline2000 from "src/components/timeline-2000";
import Timeline2010 from "src/components/timeline-2010";


class PortTimeline extends React.Component {
  constructor(props) {
    super(props);
    this.section1890 = this.section1890.bind(this);
    this.section1900 = this.section1900.bind(this);
    this.section1910 = this.section1910.bind(this);
    this.section1920 = this.section1920.bind(this);
    this.section1930 = this.section1930.bind(this);
    this.section1940 = this.section1940.bind(this);
    this.section1950 = this.section1950.bind(this);
    this.section1960 = this.section1960.bind(this);
    this.section1970 = this.section1970.bind(this);
    this.section1980 = this.section1980.bind(this);
    this.section1990 = this.section1990.bind(this);
    this.section2000 = this.section2000.bind(this);
    this.section2010 = this.section2010.bind(this);
    this.handleNextDecadeSwitch = this.handleNextDecadeSwitch.bind(this);
    this.handlePreviousDecadeSwitch = this.handlePreviousDecadeSwitch.bind(this);
  }

  state = {
    currentDecade: 1890
  }


  section1890(isVisible) {
    if (isVisible) {
      this.setState({ currentDecade: 1890 });
    }
  }

  section1900(isVisible) {
    if (isVisible) {
      this.setState({ currentDecade: 1900 });
    }
  }

  section1910(isVisible) {
    if (isVisible) {
      this.setState({ currentDecade: 1910 });
    }
  }

  section1920(isVisible) {
    if (isVisible) {
      this.setState({ currentDecade: 1920 });
    }
  }

  section1930(isVisible) {
    if (isVisible) {
      this.setState({ currentDecade: 1930 });
    }
  }

  section1940(isVisible) {
    if (isVisible) {
      this.setState({ currentDecade: 1940 });
    }
  }

  section1950(isVisible) {
    if (isVisible) {
      this.setState({ currentDecade: 1950 });
    }
  }

  section1960(isVisible) {
    if (isVisible) {
      this.setState({ currentDecade: 1960 });
    }
  }

  section1970(isVisible) {
    if (isVisible) {
      this.setState({ currentDecade: 1970 });
    }
  }

  section1980(isVisible) {
    if (isVisible) {
      this.setState({ currentDecade: 1980 });
    }
  }

  section1990(isVisible) {
    if (isVisible) {
      this.setState({ currentDecade: 1990 });
    }
  }

  section2000(isVisible) {
    if (isVisible) {
      this.setState({ currentDecade: 2000 });
    }
  }

  section2010(isVisible) {
    if (isVisible) {
      this.setState({ currentDecade: 2010 });
    }
  }


  handlePreviousDecadeSwitch() {
    this.setState({ currentDecade: this.state.currentDecade - 10 });
  }

  handleNextDecadeSwitch() {
    this.setState({ currentDecade: this.state.currentDecade + 10 });
  }

  render() {
    return (
      <React.Fragment>
        <Icons />
        <div className="timeline--page">
          <Link to="/port-info/history#port-history-timeline" className="form-search-subpage__btn button--hover--cursor timeline--page--close"><svg className="icon--close--search"><use xlinkHref="#icon-close" /></svg></Link>
          <div className="timeline--page__header">
            <h2 className="timeline--page__header__title">Port Of Long Beach<br />History Timeline</h2>
            <h4 className="timeline--page__header__excerpt--title">Important Events By Decade</h4>
            <p className="intro">From the Port's roots to today</p>
          </div>

          <div className="timeline--page__body gutter">
            <div className="timeline--page__body__decade--switch">
              <button type="button" disabled={this.state.currentDecade === 1890} className="global-search-close form-search-subpage__btn button--hover--cursor previous--decade" onClick={this.handlePreviousDecadeSwitch}><a href={`#${this.state.currentDecade}--section`}><img src={ArrowButton} alt="" /></a></button>
              <span className="year--display intro">{this.state.currentDecade}</span>
              <button type="button" disabled={this.state.currentDecade === 2010} className="global-search-close form-search-subpage__btn button--hover--cursor next--decade" onClick={this.handleNextDecadeSwitch}><a href={`#${this.state.currentDecade}--section`}><img src={ArrowButton} alt="" /></a></button>
            </div>

            <VisabilitySensor onChange={this.section1890} partialVisibility minTopValue={200} scrollCheck={true} resizeCheck={true}>
              <Timeline1890 />
            </VisabilitySensor>

            <VisabilitySensor onChange={this.section1900} partialVisibility minTopValue={400} scrollCheck={true} resizeCheck={true}>
              <Timeline1900 />
            </VisabilitySensor>

            <VisabilitySensor onChange={this.section1910} partialVisibility minTopValue={400} scrollCheck={true} resizeCheck={true}>
              <Timeline1910 />
            </VisabilitySensor>

            <VisabilitySensor onChange={this.section1920} partialVisibility minTopValue={400} scrollCheck={true} resizeCheck={true}>
              <Timeline1920 />
            </VisabilitySensor>

            <VisabilitySensor onChange={this.section1930} partialVisibility minTopValue={400} scrollCheck={true} resizeCheck={true}>
              <Timeline1930 />
            </VisabilitySensor>

            <VisabilitySensor onChange={this.section1940} partialVisibility minTopValue={400} scrollCheck={true} resizeCheck={true}>
              <Timeline1940 />
            </VisabilitySensor>

            <VisabilitySensor onChange={this.section1950} partialVisibility minTopValue={400} scrollCheck={true} resizeCheck={true}>
              <Timeline1950 />
            </VisabilitySensor>

            <VisabilitySensor onChange={this.section1960} partialVisibility minTopValue={400} scrollCheck={true} resizeCheck={true}>
              <Timeline1960 />
            </VisabilitySensor>

            <VisabilitySensor onChange={this.section1970} partialVisibility minTopValue={400} scrollCheck={true} resizeCheck={true}>
              <Timeline1970 />
            </VisabilitySensor>

            <VisabilitySensor onChange={this.section1980} partialVisibility minTopValue={400} scrollCheck={true} resizeCheck={true}>
              <Timeline1980 />
            </VisabilitySensor>

            <VisabilitySensor onChange={this.section1990} partialVisibility minTopValue={400} scrollCheck={true} resizeCheck={true}>
              <Timeline1990 />
            </VisabilitySensor>

            <VisabilitySensor onChange={this.section2000} partialVisibility minTopValue={400} scrollCheck={true} resizeCheck={true}>
              <Timeline2000 />
            </VisabilitySensor>

            <VisabilitySensor onChange={this.section2010} partialVisibility minTopValue={400} scrollCheck={true} resizeCheck={true}>
              <Timeline2010 />
            </VisabilitySensor>
          </div>
        </div>
      </React.Fragment>
    )
  }
}


export default PortTimeline;