import React from "react";
import ReactHtmlParser from "react-html-parser";
import { StaticQuery, graphql } from "gatsby";
import GalleryAlt from "src/components/gallery/gallery-alt";

// import education from "src/images/community/card-education-programs.jpg";
// import grants from "src/images/community/card-grants.jpg";
// import relations from "src/images/community/card-relations-requests.jpg";
// import sponsorships from "src/images/community/card-sponsorships.jpg";
// import tours from "src/images/community/card-harbor-tours.jpg";


class Timeline1900 extends React.Component {
  constructor(props) {
    super(props);
    this.replaceWithComponents = this.replaceWithComponents.bind(this);
  }

  state = {
    // photos: [education, grants, relations, sponsorships, tours],
    excerpts: ["Picture description", "Another picture description", "More description", "4th description", "Last description"],
    index: 0
  }


  replaceWithComponents(node, index) {
    // Replace Envira Gallery code with Gallery component
    if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("envira-gallery-wrap") > -1) && (node.attribs.class.indexOf("envira-album-wrap") < 0)) {
      const galleryInfo = node.children[1].attribs['data-gallery-images'];

      return <GalleryAlt key={index} galleryInfo={galleryInfo} previewMode="thumbs" />;

      // let galleryConfig;

      // for (let i = 0; i < node.children.length; i++) {
      //   if (node.children[i].attribs['data-gallery-config']) {
      //     galleryConfig = node.children[i];
      //     break;
      //   }
      // }

      // return <Gallery key={index} galleryConfig={galleryConfig.attribs['data-gallery-config']} galleryInfo={galleryConfig.attribs['data-gallery-images']} previewMode="thumbs" downloadable={galleryConfig} />;
    }
  }


  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            wordpressPage(tagNames: { eq: "dev_only_id_timeline_1900" }) {
              id
              slug
              title
              content
            }
          }
        `}

        render={data => (
          <div id="1900--section" className="timeline--page__body__info">
            <div className="content content--subpage envira--section--right">
              {ReactHtmlParser(data.wordpressPage.content, { transform: this.replaceWithComponents })}
            </div>
            <div className="text--section--left">
              <h3 className="heading--med text--section--left__heading">1900</h3>
              <h4 className="text--section--left__title">1905</h4>
              <p className="headline__intro intro">October - The Los Angeles Dock and Terminal Co. begins development of Long Beach Harbor by purchasing 800 acres of slough and salt marshes - an area that later becomes the inner harbor. On its executive committee is Charles Windham, later city manager and mayor of Long Beach, who later becomes known as the "Father of the Port."</p>
              <h4 className="text--section--left__title">1907</h4>
              <p className="headline__intro intro">Construction begins on the Craig Shipyard in the inner harbor, the first industry in the Port of Long Beach. Craig is also awarded a contract to dredge a channel from the open ocean to the inner harbor.</p>
              <h4 className="text--section--left__title">1908</h4>
              <p className="headline__intro intro">Aplications are filed with the Water Department for a permit to start dredging the Cerritos Channel, connecting the Port of Long Beach with the Port of Los Angeles.</p>
              <p className="headline__intro intro">The Salt Lake Railroad's bascule or "jack-knife" drawbridge connecting Long Beach to Terminal Island opens. Later, the pontoon bridge, the Gerald Desmond Bridge and now the Long Beach International Gateway Bridge provide the same connection.</p>
              <h4 className="text--section--left__title">1909</h4>
              <p className="headline__intro intro">June 30 - The sand bar between the ocean and what was then called the San Gabriel River (now known as the Los Angeles River) washes out at high tide, making the ocean entrance to the newly dredged inner harbor a reality.</p>
              <p className="headline__intro intro">The Long Beach electorate approves a harbor bond issue for $245,000 to purchase water frontage in the inner harbor and cover costs of building new piers, wharves and sheds.</p>
            </div>
          </div>
        )}
      />
    );
  }
}


export default Timeline1900;

