import React from "react";
import ReactHtmlParser from "react-html-parser";
import { StaticQuery, graphql } from "gatsby";
import GalleryAlt from "src/components/gallery/gallery-alt";

// import education from "src/images/community/card-education-programs.jpg";
// import grants from "src/images/community/card-grants.jpg";
// import relations from "src/images/community/card-relations-requests.jpg";
// import sponsorships from "src/images/community/card-sponsorships.jpg";
// import tours from "src/images/community/card-harbor-tours.jpg";


class Timeline1910 extends React.Component {
    constructor(props) {
        super(props);
        this.replaceWithComponents = this.replaceWithComponents.bind(this);
    }

    state = {
        // photos: [education, grants, relations, sponsorships, tours],
        excerpts: ["Picture description", "Another picture description", "More description", "4th description", "Last description"],
        index: 0
    }


    replaceWithComponents(node, index) {
        // Replace Envira Gallery code with Gallery component
        if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("envira-gallery-wrap") > -1) && (node.attribs.class.indexOf("envira-album-wrap") < 0)) {
            const galleryInfo = node.children[1].attribs['data-gallery-images'];

            return <GalleryAlt key={index} galleryInfo={galleryInfo} previewMode="thumbs" />;

            // let galleryConfig;

            // for (let i = 0; i < node.children.length; i++) {
            //     if (node.children[i].attribs['data-gallery-config']) {
            //         galleryConfig = node.children[i];
            //         break;
            //     }
            // }

            // return <Gallery key={index} galleryConfig={galleryConfig.attribs['data-gallery-config']} galleryInfo={galleryConfig.attribs['data-gallery-images']} previewMode="thumbs" downloadable={galleryConfig} />;
        }
    }


    render() {
        return (
            <StaticQuery
                query={graphql`
                    query {
                        wordpressPage(tagNames: { eq: "dev_only_id_timeline_1910" }) {
                        id
                        slug
                        title
                        content
                        }
                    }
                `}

                render={data => (
                    <div id="1910--section" className="timeline--page__body__info">
                        <div className="content content--subpage envira--section--left">
                            {ReactHtmlParser(data.wordpressPage.content, { transform: this.replaceWithComponents })}
                        </div>
                        <div className="text--section--right">
                            <h3 className="heading--med text--section--right__heading">1910</h3>
                            <h4 className="text--section--right__title">1910</h4>
                            <p className="headline__intro intro">December 3 - The 256-foot lumber vessel General Hubbard, the first steel full-size ship built in Southern California, is launched at Craig Shipbuilding. Many civic luminaries turn out for the event.</p>
                            <h4 className="text--section--right__title">1911</h4>
                            <p className="headline__intro intro">The State of California grants the tidelands areas to the City of Long Beach for port operations. Tidelands are defined as those lands and water areas along the coast of the Pacific Ocean within the city limits.</p>
                            <p className="headline__intro intro">The tidelands are granted to the City of Long Beach in trust for the people of the State. The Tidelands Trust not only restricts the use of the tidelands, but also the use of income and revenue generated from businesses and activities conducted on the tidelands. The tidelands and tidelands revenues must be used for purposes related to harbor commerce, navigation, marine recreation and fisheries.</p>
                            <p className="headline__intro intro">June 2 -  The S.S. Iaqua offloads 280,000 feet of redwood lumber at the harbor's Municipal Pier, and the Port of Long Beach is officially in business.</p>
                            <p className="headline__intro intro">June 24 - The Port of Long Beach is officially dedicated when Mayor Charles Windham and the Municipal Band lead a parade from Pine Avenue and Ocean to Pier 1 on Channel 3 in the inner harbor.</p>
                            <h4 className="text--section--right__title">1912</h4>
                            <p className="headline__intro intro">The first passenger service from the Port of Long Beach is launched, a short-lived route between Long Beach and San Francisco, via Santa Barbara, aboard the SS Santa Clara.</p>
                            <h4 className="text--section--right__title">1916</h4>
                            <p className="headline__intro intro">The Los Angeles Dock and Terminal Co. declares bankruptcy and turns over the harbor's dredging projects to the City of Long Beach. The city completes the dredging of channels and a turning basin.</p>
                            <h4 className="text--section--right__title">1917</h4>
                            <p className="headline__intro intro">The first Board of Harbor Commissioners is formed to oversee harbor operations, with members W.T. Lisenby, mayor and commissioner of public property; James R. Williams, commissioner of public safety; and C.J. Hargis, commissioner of public works.</p>
                            <h4 className="text--section--right__title">1919</h4>
                            <p className="headline__intro intro">Although there is as yet no official homeport designation, the Navy begins using the ports of Long Beach and Los Angeles as a center of operations for the Pacific Fleet.</p>
                        </div>
                    </div>
                )}
            />
        );
    }
}


export default Timeline1910;

