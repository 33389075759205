import React from "react";

import Carousel, { Modal, ModalGateway } from 'react-images';

// These override the default react-images <View /> and <Footer /> components.
// See http://jossmac.github.io/react-images/#/components for more info on custom components.
import GalleryCustomView from "src/components/gallery/gallery-custom-view";
import GalleryCustomFooter from "src/components/gallery/gallery-custom-footer";

/**
 *  Photo/video gallery
 */
class GalleryAlt extends React.Component {
  constructor(props) {
    super(props);
    this.getModalGateway = this.getModalGateway.bind(this);
  }

  state = {
    modalIsOpen: false,
    selectedIndex: 0
  };

  toggleModal = (selectedIndex, keepModalOpen) => {
    this.setState(state => ({
      modalIsOpen: keepModalOpen || !state.modalIsOpen,
      selectedIndex
    }));
  };

  /**
   * @return component react-images uses to render gallery in modal
   */
  getModalGateway(modalIsOpen, selectedIndex, images) {
    return (
      <ModalGateway>
        {modalIsOpen ? (
          <Modal onClose={this.toggleModal} allowFullscreen={false}>
            <Carousel
              components={{ Footer: GalleryCustomFooter, View: GalleryCustomView }}
              currentIndex={selectedIndex}
              frameProps={{ accessibility: true }}
              hideControlsWhenIdle={false} 
              views={images}
              toggleModal={this.toggleModal}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    )
  }

  render() {
    const props = this.props;
    const data = !props.isInDocumentsPage ? JSON.parse(props.galleryInfo) : props.galleryInfo;
    const { selectedIndex, modalIsOpen } = this.state;
    const galleryImages = props.downloadable ? props.downloadable.children : null;
    // console.log("-----------------props-----------------");
    // console.log(props);
    // console.log("-----------------data-----------------");
    // console.log(data);

    // The following will result in showing a <button> instead of thumbnail images
    //   if showThumbnailsByDefault is false.
    const showThumbnailsByDefault = (props.previewMode === 'thumbs') ? true : false;

    // Full set of gallery items
    const images = data.map((image, index) => { 
      let isDownloadable = {};
      if (galleryImages) {
        if (galleryImages[index].children[0].children[3].children) {
          if (galleryImages[index].children[0].children[3].children[0] !== undefined) {
            isDownloadable = {
              link: galleryImages[index].children[0].children[3].children[0].children[1].attribs.href
            };
          } else {
            isDownloadable = {
              link: null
            };
          }
        }
      }

      const imageInfo = {
        caption: image.alt !== "" ? image.alt : "",
        source: {
          regular: image.full,
          thumbnail: image.thumbnail
        },
        video: typeof image.video === "object" ? image.video : null,
        download: isDownloadable
      }
      // image.caption is available too

      return imageInfo;
    });


    // The thumbnail images that should show in the page as prompt 
    // to full gallery (when previewMode prop is "thumbs")
    let imagesPreview;
    if (showThumbnailsByDefault) {
      imagesPreview = images.slice(0, 10);
    }


    /* 
      When showThumbnailsByDefault is true, a subset of a gallery's thumbnails will show in the page as a preview of the full gallery and as a prompt to enter the gallery. The false condition is used by Documents > Photos & Videos, which shows a button in a files table row as the prompt to view the gallery.
    */
    return (
      showThumbnailsByDefault
        ?
        <section className="gallery">
          <h4 className="visuallyhidden">Select a thumbnail image to display full image in gallery carousel</h4>
          <div className="gallery__preview">
            {imagesPreview.map(({ caption, source, video }, j) => (
              <div key={j} className={video && "gallery__video-thumb-wrapper"}>
                <img
                  alt={`Thumbnail: ${caption}`}
                  src={source.thumbnail}
                  onClick={() => this.toggleModal(j)}
                  key={source.thumbnail}
                  className="gallery__thumb"
                />
              </div>
            ))}
          </div>

          {this.getModalGateway(modalIsOpen, selectedIndex, images)}
        </section>
        :
        <React.Fragment>
          <button type="button" className="files__btn-gallery" onClick={() => this.toggleModal(0)}>{props.galleryButtonText}</button>

          {this.getModalGateway(modalIsOpen, selectedIndex, images)}
        </React.Fragment>
    );
  }
}

export default GalleryAlt;
