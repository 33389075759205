import React from "react";
import ReactHtmlParser from "react-html-parser";
import { StaticQuery, graphql } from "gatsby";
import GalleryAlt from "src/components/gallery/gallery-alt";

// import education from "src/images/community/card-education-programs.jpg";
// import grants from "src/images/community/card-grants.jpg";
// import relations from "src/images/community/card-relations-requests.jpg";
// import sponsorships from "src/images/community/card-sponsorships.jpg";
// import tours from "src/images/community/card-harbor-tours.jpg";


class Timeline1930 extends React.Component {
  constructor(props) {
    super(props);
    this.replaceWithComponents = this.replaceWithComponents.bind(this);
  }

  state = {
    // photos: [education, grants, relations, sponsorships, tours],
    excerpts: ["Picture description", "Another picture description", "More description", "4th description", "Last description"],
    index: 0
  }


  replaceWithComponents(node, index) {
    // Replace Envira Gallery code with Gallery component
    if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("envira-gallery-wrap") > -1) && (node.attribs.class.indexOf("envira-album-wrap") < 0)) {
      const galleryInfo = node.children[1].attribs['data-gallery-images'];

      return <GalleryAlt key={index} galleryInfo={galleryInfo} previewMode="thumbs" />;

      // let galleryConfig;

      // for (let i = 0; i < node.children.length; i++) {
      //   if (node.children[i].attribs['data-gallery-config']) {
      //     galleryConfig = node.children[i];
      //     break;
      //   }
      // }

      // return <Gallery key={index} galleryConfig={galleryConfig.attribs['data-gallery-config']} galleryInfo={galleryConfig.attribs['data-gallery-images']} previewMode="thumbs" downloadable={galleryConfig} />;
    }
  }


  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            wordpressPage(tagNames: { eq: "dev_only_id_timeline_1930" }) {
              id
              slug
              title
              content
            }
          }
        `}

        render={data => (
          <div id="1930--section" className="timeline--page__body__info">
            <div className="content content--subpage envira--section--left">
              {ReactHtmlParser(data.wordpressPage.content, { transform: this.replaceWithComponents })}
            </div>
            <div className="text--section--right">
              <h3 className="heading--med text--section--right__heading">1930</h3>
              <h4 className="text--section--right__title">1930</h4>
              <p className="headline__intro intro">The Federal River and Harbor Act authorizes construction of a 3.5-mile extension to the San Pedro Bay breakwater.</p>
              <p className="headline__intro intro">April 21 - The Ford Motor Company opens a plant at the western end of the Port that soon employs 2,000 people. Ford turned out cars until the late ’50s, when flooding and other problems caused by subsidence forced the company to relocate. The plant stood empty until 1990, when it was torn down.</p>
              <p className="headline__intro intro">June -  Procter & Gamble breaks ground on a factory on what is now Pier C that will stay open until 1988.</p>
              <h4 className="text--section--right__title">1931</h4>
              <p className="headline__intro intro">The Long Beach City Charter is amended to create a Harbor District, an independent and more powerful Board of Harbor Commissioners, and a Harbor Department to control and manage the Port. Soon after, the Port moves into its first separate administration building, at 130 S. Pico Ave., a site now occupied by the 710 Freeway.</p>
              <h4 className="text--section--right__title">1932</h4>
              <p className="headline__intro intro">Construction begins on the middle section of the San Pedro Bay breakwater.</p>
              <h4 className="text--section--right__title">1933</h4>
              <p className="headline__intro intro">March 10 - A 6.4-magnitude earthquake strikes Long Beach and the surrounding area, killing more than 100 people and damaging buildings and infrastructure throughout the city. Damage at the Port, however, is minimal. The historic frigate USS Constitution, better known as “Old Ironsides,” was visiting Long Beach at the time.</p>
              <h4 className="text--section--right__title">1934</h4>
              <p className="headline__intro intro">Several months of violent labor struggles along the West Coast (including Long Beach and San Pedro) culminate July 5 in “Bloody Thursday” in San Francisco. The aftermath led to improvements in conditions and pay for longshore workers and, in 1937, to the formation of the International Longshore and Warehouse Union, which still represents most dockworkers today.</p>
              <h4 className="text--section--right__title">1936</h4>
              <p className="headline__intro intro">Oil is discovered in the harbor.</p>
              <h4 className="text--section--right__title">1938</h4>
              <p className="headline__intro intro">The first oil well is brought in to the Harbor District, providing oil revenues to the City and Port of Long Beach but triggering decades of legal squabbles over who controlled the revenue.The first transit shed on Pier A in the outer harbor is completed.</p>
              <h4 className="text--section--right__title">1939</h4>
              <p className="headline__intro intro">A hydraulic dredging project begins in the inner harbor, creating a land mass equal to nine city blocks, south of Seaside Boulevard.</p>
            </div>
          </div>
        )}
      />
    );
  }
}


export default Timeline1930;