import React from "react";
import ReactHtmlParser from "react-html-parser";
import { StaticQuery, graphql } from "gatsby";
import GalleryAlt from "src/components/gallery/gallery-alt";

// import education from "src/images/community/card-education-programs.jpg";
// import grants from "src/images/community/card-grants.jpg";
// import relations from "src/images/community/card-relations-requests.jpg";
// import sponsorships from "src/images/community/card-sponsorships.jpg";
// import tours from "src/images/community/card-harbor-tours.jpg";


class Timeline1960 extends React.Component {
  constructor(props) {
    super(props);
    this.replaceWithComponents = this.replaceWithComponents.bind(this);
  }

  state = {
    // photos: [education, grants, relations, sponsorships, tours],
    excerpts: ["Picture description", "Another picture description", "More description", "4th description", "Last description"],
    index: 0
  }


  replaceWithComponents(node, index) {
    // Replace Envira Gallery code with Gallery component
    if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("envira-gallery-wrap") > -1) && (node.attribs.class.indexOf("envira-album-wrap") < 0)) {
      const galleryInfo = node.children[1].attribs['data-gallery-images'];

      return <GalleryAlt key={index} galleryInfo={galleryInfo} previewMode="thumbs" />;

      // let galleryConfig;

      // for (let i = 0; i < node.children.length; i++) {
      //   if (node.children[i].attribs['data-gallery-config']) {
      //     galleryConfig = node.children[i];
      //     break;
      //   }
      // }

      // return <Gallery key={index} galleryConfig={galleryConfig.attribs['data-gallery-config']} galleryInfo={galleryConfig.attribs['data-gallery-images']} previewMode="thumbs" downloadable={galleryConfig} />;
    }
  }


  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            wordpressPage(tagNames: { eq: "dev_only_id_timeline_1960" }) {
              id
              slug
              title
              content
            }
          }
        `}

        render={data => (
          <div id="1960--section" className="timeline--page__body__info">
            <div className="content content--subpage envira--section--right">
              {ReactHtmlParser(data.wordpressPage.content, { transform: this.replaceWithComponents })}
            </div>
            <div className="text--section--left">
              <h3 className="heading--med text--section--left__heading">1960</h3>
              <h4 className="text--section--left__title">1960</h4>
              <p className="headline__intro intro">Operation "Big Squirt," a water injection program, halts subsidence. A later estimate for the cost of subsidence: $90 million to halt the sinking and 25 years of lost industrial and commercial growth.</p>
              <p className="headline__intro intro">The Harbor Department moves into a $2 million, modern, eight-story headquarters at 925 Harbor Plaza. The Port had outgrown its previous HQ and the earlier building had also been seriously affected by subsidence. The dedication ceremony featured a “wedding of the waters,” with 31 young women representing a foreign or U.S. port, holding a container of water from that port, and then combining the waters in the building’s reflecting pool.</p>
              <h4 className="text--section--left__title">1962</h4>
              <p className="headline__intro intro">Construction begins on Piers J and F.</p>
              <p className="headline__intro intro">Sea-Land Services, a U.S.-flagged shipping company, begins operations at their Pier G container terminal in Long Beach. The carrier is the first to offer an innovative cargo handling system called containerization. Started on the U.S. East Coast in 1956, containerization places cargo in shipping containers, or steel boxes, for transport on trucks to seaport docks. Containers are loaded onto vessels, transported and off-loaded at their destination. Containerization revolutionizes the shipping industry.</p>
              <h4 className="text--section--left__title">1965</h4>
              <p className="headline__intro intro">Piers J and F are completed and add 310 acres of landfill to Long Beach. The project requires 3.35 million tons of rock and 30 million cubic yards of hydraulic fill. The landfill expansion is the world's largest at that time.</p>
              <p className="headline__intro intro">Long Beach Harbor’s distinctive oil islands are completed, with beautification touches to hide oil derricks from the mainland. Operated by a consortium called THUMS – Texaco, Humble, Union, Mobil and Shell – the islands were named in 1967 for fallen NASA astronauts Grissom, White, Chaffee and Freeman.</p>
              <h4 className="text--section--left__title">1967</h4>
              <p className="headline__intro intro">The Queen Mary, destined to become an icon of Long Beach, arrives at the Port on December 10 after a voyage from England around the tip of South America (the vessel was too big for the Panama Canal). After four years of work at the Naval Shipyard, the QM was moved to what is now Pier H and opened as a hotel, museum and tourist attraction in 1971.</p>
              <h4 className="text--section--left__title">1968</h4>
              <p className="headline__intro intro">The Gerald Desmond Bridge opens, connecting Long Beach and Terminal Island. The $13 million span replaced the pontoon bridge, which had been in place since the 1940s and was often a traffic bottleneck. By the mid-2010s, it was estimated that around 15 percent of the nation’s waterborne container traffic passed over the bridge. A replacement bridge, higher and wider, opened in October 2020.</p>
            </div>
          </div>
        )}
      />
    );
  }
}


export default Timeline1960;