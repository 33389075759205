import React from "react";
import ReactHtmlParser from "react-html-parser";
import { StaticQuery, graphql } from "gatsby";
import GalleryAlt from "src/components/gallery/gallery-alt";

// import education from "src/images/community/card-education-programs.jpg";
// import grants from "src/images/community/card-grants.jpg";
// import relations from "src/images/community/card-relations-requests.jpg";
// import sponsorships from "src/images/community/card-sponsorships.jpg";
// import tours from "src/images/community/card-harbor-tours.jpg";


class Timeline1920 extends React.Component {
    constructor(props) {
        super(props);
        this.replaceWithComponents = this.replaceWithComponents.bind(this);
    }

    state = {
        // photos: [education, grants, relations, sponsorships, tours],
        excerpts: ["Picture description", "Another picture description", "More description", "4th description", "Last description"],
        index: 0
    }


    replaceWithComponents(node, index) {
        // Replace Envira Gallery code with Gallery component
        if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("envira-gallery-wrap") > -1) && (node.attribs.class.indexOf("envira-album-wrap") < 0)) {
            const galleryInfo = node.children[1].attribs['data-gallery-images'];

            return <GalleryAlt key={index} galleryInfo={galleryInfo} previewMode="thumbs" />;

            // let galleryConfig;

            // for (let i = 0; i < node.children.length; i++) {
            //     if (node.children[i].attribs['data-gallery-config']) {
            //         galleryConfig = node.children[i];
            //         break;
            //     }
            // }

            // return <Gallery key={index} galleryConfig={galleryConfig.attribs['data-gallery-config']} galleryInfo={galleryConfig.attribs['data-gallery-images']} previewMode="thumbs" downloadable={galleryConfig} />;
        }
    }


    render() {
        return (
            <StaticQuery
                query={graphql`
                    query {
                        wordpressPage(tagNames: { eq: "dev_only_id_timeline_1920" }) {
                        id
                        slug
                        title
                        content
                        }
                    }
                `}

                render={data => (
                    <div id="1920--section" className="timeline--page__body__info">
                        <div className="content content--subpage envira--section--right">
                            {ReactHtmlParser(data.wordpressPage.content, { transform: this.replaceWithComponents })}
                        </div>
                        <div className="text--section--left">
                            <h3 className="heading--med text--section--left__heading">1920</h3>
                            <h4 className="text--section--left__title">1921</h4>
                            <p className="headline__intro intro">Oil is discovered in Signal Hill, beginning an oil boom for the area that would continue for decades but have serious consequences for the Port later.</p>
                            <h4 className="text--section--left__title">1924</h4>
                            <p className="headline__intro intro">Long Beach voters approve a $5 million bond issue for extension of the breakwater, improvement of the inner harbor and preliminary development of the outer harbor.</p>
                            <h4 className="text--section--left__title">1925</h4>
                            <p className="headline__intro intro">The Board of Harbor Commissioners is expanded to a five-member board with members who are not already city officials.</p>
                            <h4 className="text--section--left__title">1926</h4>
                            <p className="headline__intro intro">Long Beach attains "deep water" port status. More than 1 million tons of cargo are handled by 821 vessel calls.</p>
                            <p className="headline__intro intro">Construction begins on Pier A in the outer harbor.</p>
                            <h4 className="text--section--left__title">1928</h4>
                            <p className="headline__intro intro">The port begins construction of additional piers, wharves and facilities. Municipal Wharf No. 1 is equipped with a new transit shed.</p>
                            <p className="headline__intro intro">Summer - The Pacific Southwest Exposition draws more than a million people to the Seventh Street Peninsula during a roughly six-week run. The World’s Fair-type event featured pavilions from other nations, exhibitions of arts and manufactured goods, and an amusement zone. The space was later occupied by Procter & Gamble and is now the Matson terminal on Pier C.</p>
                        </div>
                    </div>
                )}
            />
        );
    }
}


export default Timeline1920;

