import React from "react";
import ReactHtmlParser from "react-html-parser";
import { StaticQuery, graphql } from "gatsby";
import GalleryAlt from "src/components/gallery/gallery-alt";

// import education from "src/images/community/card-education-programs.jpg";
// import grants from "src/images/community/card-grants.jpg";
// import relations from "src/images/community/card-relations-requests.jpg";
// import sponsorships from "src/images/community/card-sponsorships.jpg";
// import tours from "src/images/community/card-harbor-tours.jpg";


class Timeline1970 extends React.Component {
  constructor(props) {
    super(props);
    this.replaceWithComponents = this.replaceWithComponents.bind(this);
  }

  state = {
    // photos: [education, grants, relations, sponsorships, tours],
    excerpts: ["Picture description", "Another picture description", "More description", "4th description", "Last description"],
    index: 0
  }


  replaceWithComponents(node, index) {
    // Replace Envira Gallery code with Gallery component
    if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("envira-gallery-wrap") > -1) && (node.attribs.class.indexOf("envira-album-wrap") < 0)) {
      const galleryInfo = node.children[1].attribs['data-gallery-images'];

      return <GalleryAlt key={index} galleryInfo={galleryInfo} previewMode="thumbs" />;

      // let galleryConfig;

      // for (let i = 0; i < node.children.length; i++) {
      //   if (node.children[i].attribs['data-gallery-config']) {
      //     galleryConfig = node.children[i];
      //     break;
      //   }
      // }

      // return <Gallery key={index} galleryConfig={galleryConfig.attribs['data-gallery-config']} galleryInfo={galleryConfig.attribs['data-gallery-images']} previewMode="thumbs" downloadable={galleryConfig} />;
    }
  }


  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            wordpressPage(tagNames: { eq: "dev_only_id_timeline_1970" }) {
              id
              slug
              title
              content
            }
          }
        `}

        render={data => (
          <div id="1970--section" className="timeline--page__body__info">
            <div className="content content--subpage envira--section--left">
              {ReactHtmlParser(data.wordpressPage.content, { transform: this.replaceWithComponents })}
            </div>
            <div className="text--section--right">
              <h3 className="heading--med text--section--right__heading">1970</h3>
              <h4 className="text--section--right__title">1971</h4>
              <p className="headline__intro intro">The port completes a $7.6 million expansion on Pier J and develops a 55-acre combination container and automobile terminal. The facility processes Toyota's distribution throughout Southern California and the Western U.S.</p>
              <h4 className="text--section--right__title">1972</h4>
              <p className="headline__intro intro">International Transportation Service, Inc. opens a $10 million, 52-acre container terminal on Pier J. The new terminal features a 1,200-foot wharf, 42-foot water depth and two high-speed Paceco gantry cranes. ITS customers include "K" Line and Zim Container Service.</p>
              <h4 className="text--section--right__title">1973</h4>
              <p className="headline__intro intro">The Port of Long Beach wins the American Association of Port Authorities Environmental "E" Award for improvement and protection of the environment. Long Beach wins the honor for its comprehensive program and broad scope of achievement in environmental matters including oil spill prevention and control, debris removal, harbor sewerage system, port beautification, vessel traffic control and water sampling program. Long Beach is the first harbor in the Western Hemisphere to be accorded this honor.</p>
              <p className="headline__intro intro">Sea-Land Service moves into an 80-acre container terminal and a 15-acre rail and truck facility on Pier G.</p>
              <h4 className="text--section--right__title">1974</h4>
              <p className="headline__intro intro">The Port of Long Beach becomes the first major Pacific Coast seaport to earn the "E-Star" Award from the U.S. Department of Commerce for export service. The port is cited for its continued and expanded efforts in facilitating and encouraging exports.</p>
              <p className="headline__intro intro">Pacific Container Terminal opens a 34.5-acre facility on Pier J.</p>
              <h4 className="text--section--right__title">1975</h4>
              <p className="headline__intro intro">The $50 million container complex expansion of Pier J's southeast basin is completed, with ten berths and 12 gantry cranes.</p>
              <p className="headline__intro intro">The port joins Standard Oil of Ohio (SOHIO) in an environmental impact report of SOHIO's proposed tanker terminal south of Pier J; the project was never built.</p>
              <p className="headline__intro intro">The arrival of the T.S. Adrian Maersk marks the inauguration of the Danish-flagged carrier's express container service between the U.S. and Asia.</p>
              <h4 className="text--section--right__title">1978</h4>
              <p className="headline__intro intro">Maersk Line Pacific, Ltd. opens a 29-acre container terminal on Pier G.</p>
              <h4 className="text--section--right__title">1979</h4>
              <p className="headline__intro intro">Long Beach acquires 140 acres for new inner harbor auto terminals for Toyota and Pasha Industries.</p>
              <p className="headline__intro intro">The port completes a $20 million conversion of Pier E from outmoded breakbulk terminals to an ultra-modern multipurpose cargo facility operated by California United Terminals. The omni-terminal handles containers, roll on/roll off and breakbulk commodities.</p>
              <p className="headline__intro intro">Hanjin Container Lines initiates service between Long Beach and Asia.</p>
              <p className="headline__intro intro">Long Beach port officials visit the People's Republic of China for the first time following normalization of the United States' relations with China.</p>
            </div>
          </div>
        )}
      />
    );
  }
}


export default Timeline1970;